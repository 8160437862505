<script setup lang="ts">
defineSlice({
  name: { label: 'Bento AI Illustration', group: 'Bento', layouts: ['VoixMarketing'] },
  description: 'AI illustration for the bento system',
  preview: 'SlicesBentoAiIllustration.vue',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const activated = inject('activated', false)
</script>

<template>
  <div class="w-full h-full">
    <svg
      class="w-full h-full ease-out duration-[2s] delay-500" :class="{ '-translate-y-32': !activated }" viewBox="0 0 2457 746" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
    >
      <mask id="trackMask">
        <path d="M1232.93,0.886l-0,744.226" style="fill:none;stroke:#fff;stroke-width:3px;" />

        <path

          d="M1281.74,0.886l0,128.672c0,0 291.099,494.008 291.099,543.334c-0,6.41 -0.002,12.374 -0.005,17.899c-0.02,36.991 -0.097,54.321 -0.097,54.321"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1331.82,0.886l-0,123.502c-0,27.113 561.441,488.961 561.441,550.223l0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1374.14,0.886l0,123.502c0,27.113 804.15,488.961 804.15,550.223l0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1419.43,0.886l-0,123.502c-0,27.113 1037.08,488.961 1037.08,550.223l-0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1038.26,0.886l-0,123.502c-0,27.113 -1037.08,488.961 -1037.08,550.223l-0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1179.19,0.886l0,128.672c0,0 -291.099,494.008 -291.099,543.334c0,6.41 0.002,12.374 0.005,17.899c0.02,36.991 0.098,54.321 0.098,54.321"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1129.11,0.886l0,123.502c0,27.113 -561.441,488.961 -561.441,550.223l-0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
        <path
          d="M1086.79,0.886l-0,123.502c-0,27.113 -804.15,488.961 -804.15,550.223l-0,70.501"
          style="fill:none;stroke:#fff;stroke-width:3px;" class="svg-path"
        />
      </mask>

      <defs>
        <linearGradient
          id="move-up-gradient"
          x1="362.013" y1="-25.478" x2="362.013" y2="240.781" gradientUnits="userSpaceOnUse" data-v-8cde9d93=""
        >
          <stop offset="0" stop-color="#FFFD5A" stop-opacity="1" data-v-8cde9d93="" />
          <stop offset="0.7" stop-color="#FFFD5A" stop-opacity="0.35" data-v-8cde9d93="" />
          <stop offset="1" stop-color="#FFFD5A" stop-opacity="0" data-v-8cde9d93="" /></linearGradient>
      </defs>

      <g mask="url(#trackMask)">
        <rect fill="url(#move-up-gradient)" class="duration-[4s] ease-out" :class="{ 'translate-y-[800px]': !activated, '-translate-y-[150px]': activated }" width="100%" height="100%" />
      </g>

      <g>
        <path d="M1232.93,0.886l-0,744.226" class="svg-path" />

        <path

          d="M1281.74,0.886l0,128.672c0,0 291.099,494.008 291.099,543.334c-0,6.41 -0.002,12.374 -0.005,17.899c-0.02,36.991 -0.097,54.321 -0.097,54.321"
          class="svg-path"
        />
        <path
          d="M1331.82,0.886l-0,123.502c-0,27.113 561.441,488.961 561.441,550.223l0,70.501"
          class="svg-path"
        />
        <path
          d="M1374.14,0.886l0,123.502c0,27.113 804.15,488.961 804.15,550.223l0,70.501"
          class="svg-path"
        />
        <path
          d="M1419.43,0.886l-0,123.502c-0,27.113 1037.08,488.961 1037.08,550.223l-0,70.501"
          class="svg-path"
        />
        <path
          d="M1038.26,0.886l-0,123.502c-0,27.113 -1037.08,488.961 -1037.08,550.223l-0,70.501"
          class="svg-path"
        />
        <path
          d="M1179.19,0.886l0,128.672c0,0 -291.099,494.008 -291.099,543.334c0,6.41 0.002,12.374 0.005,17.899c0.02,36.991 0.098,54.321 0.098,54.321"
          class="svg-path"
        />
        <path
          d="M1129.11,0.886l0,123.502c0,27.113 -561.441,488.961 -561.441,550.223l-0,70.501"
          class="svg-path"
        />
        <path
          d="M1086.79,0.886l-0,123.502c-0,27.113 -804.15,488.961 -804.15,550.223l-0,70.501"
          class="svg-path"
        />
      </g>

      <g>
        <path class="scale-[4] translate-y-[800px] -translate-x-[40px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[250px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[540px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[860px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[1200px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[1550px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[1850px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[2150px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
        <path class="scale-[4] translate-y-[800px] translate-x-[2420px] duration-300 ease-out" :class="{ 'stroke-neutral-500': !activated, 'stroke-voix-stroke': activated }" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2" />
      </g>

      <g>
        <rect class="duration-[2s] ease-out delay-[1.5s] -translate-y-[150px] translate-x-[980px]" :class="{ ' fill-voix-stroke/15': !activated, 'fill-voix-stroke': activated }" width="500" height="150" rx="15" />
        <rect class="duration-[2s] ease-out delay-[1.7s] -translate-y-[150px] translate-x-[980px]" :class="{ ' fill-voix-stroke/15': !activated, 'fill-voix-stroke blur-3xl animate-pulse': activated }" width="500" height="150" rx="15" />
      </g>
    </svg>
  </div>
</template>

<style scoped>
.svg-path {
  @apply stroke-neutral-800;
  transform: translateZ(0);
  backface-visibility: hidden;
  fill:none;
  stroke-width:5px;
}
</style>
